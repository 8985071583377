<template>
  <div v-show="ready" class="synoptic-wrapper" ref="wrapper">
    <div
      ref="synoptic"
      class="synoptic noselect screenshot"
      :style="style"
      v-on:click.self="onClearSelection"
      @drop="handleDrop($event)"
      @contextmenu.stop="openControlMenu"
    >
      <template v-if="ready">
        <SynopticControl
          v-for="(item, ix) in currentControls"
          :key="ix"
          :control="item"
          :equipment="equipment"
          :mode="mode"
          :zoom="zoom"
        />
      </template>
      <portal to="contextmenu">
        <ControlContextMenu ref="menu" v-if="mode == 'editor'" />
      </portal>
    </div>
  </div>
</template>

<script>
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import Controls from "@/assets/dashboard/controls.json";
import SynopticControl from "@/components/synoptic/synoptic-control.vue";
import SynopticPropertyEditor from "@/components/synoptic/property-editor";
import { isInputControl } from "@/components/synoptic/synoptic-control.vue";
import CommandFormManager from "@/utils/command-form-manager.js";
const ResizeObserver = window.ResizeObserver || Polyfill;
import {
  writeToClipboard,
  readFromClipboard,
  eventMatchesSelector
} from "@/utils";
import { debounce } from "lodash";
import ControlContextMenu from "@/components/editor/control-context-menu";
export default {
  name: "SynopticDisplay",
  components: {
    SynopticControl,
    ControlContextMenu
  },
  data: function() {
    return {
      ready: false,
      zoomFactor: 1
    };
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    display: {
      type: Object,
      required: true
    },
    mode: { type: String, required: true, default: "viewer" },
    panel: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isEditing: { type: Boolean, required: false, default: false }
  },
  computed: {
    canvas() {
      return this?.panel?.options?.canvas || {};
    },
    canvasSize() {
      return {
        w: ((this.canvas?.style?.width || "") + "").replace(/px/gi, ""),
        h: ((this.canvas?.style?.height || "") + "").replace(/px/gi, "")
      };
    },
    style() {
      let printout = this.$route.query.media == "print";
      let zoom = Math.round(this.zoom * 10000) / 10000;
      let width = this.canvasSize.w;
      let height = this.canvasSize.h;
      //================
      let style = {
        "max-width": "100%",
        "min-height": "400px",
        "max-height": "1080px"
      };

      if (navigator.userAgent.indexOf("Firefox") >= 0) {
        style["transform-origin"] = "top left";
        style["transform"] = `scale(${zoom})`;
      } else {
        style["zoom"] = zoom;
      }
      style = Object.assign(
        style,
        this.canvas.style || {
          "background-color": "transparent"
        }
      );
      style.width = width === "" || printout ? "100%" : `${width}px`;
      style.height = height === "" ? "auto" : `${height}px`;

      if (this.canvas?.src && !this.getImageControl) {
        style["min-height"] = "600px";
      }
      return style;
    },
    zoom() {
      if (this.panelOptions && "zoom" in this.panelOptions) {
        if (this.panelOptions.zoom != "auto" && this.panelOptions.zoom) {
          return this.panelOptions.zoom;
        }
      }
      return this.zoomFactor;
    },
    panelOptions: function() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },

    title: function() {
      var panel = this.panel;
      return (panel && panel.title) || this.equipment.nome || "Untitled Screen";
    },
    getImageControl() {
      return (this.panel.options.controls || []).find(
        (item) =>
          item.synopticComponent.componentName == "SynopticImage" &&
          item.synopticComponent.src == this.panel.options.canvas.src
      );
    },
    controls: function() {
      let lst = (
        (this.panelOptions && this.panelOptions.controls) ||
        []
      ).filter(
        (i) => "synopticComponent" in i && i.enabled && i.synopticComponent
      );
      // old version ()
      if (this?.panelOptions?.canvas?.src && !this.getImageControl) {
        let control = null;
        Controls.forEach((item) => {
          if (
            !control &&
            item.template.synopticComponent.componentName == "SynopticImage"
          ) {
            control = JSON.parse(JSON.stringify(item.template));
            control.synopticComponent.src = this.panel.options.canvas.src;
            control.synopticComponent.style.border = "0px solid #afafaf";
            control.synopticComponent.clientRect.width =
              this.panel.options.canvas.style.width;
            control.synopticComponent.clientRect.height =
              this.panel.options.canvas.style.height < 600
                ? 600
                : this.panel.options.canvas.style.height;
            lst.unshift(control);
          }
        });
      }
      return lst;
    },
    signature() {
      // whenever component order or visibility is changed a new signature will be generated
      return this.mode == "editor"
        ? this.editingControls.map(({ id }) => id).join("")
        : "";
    },
    editingControls() {
      return (this.$store.getters["synoptic/controls"] || []).filter(
        ({ enabled }) => enabled
      );
    },
    currentControls() {
      if (this.mode == "editor" && this.editablePanel) {
        return this.editingControls;
      }
      // It renders input controls on top of any other type
      let lst = this.controls.map((item, ix) => {
        return {
          control: item,
          ix: isInputControl(item)
            ? -1 * (item.synopticComponent.clientRect.top + 10000)
            : -1 * (ix + 10000)
        };
      });
      return lst.sort((a, b) => b.ix - a.ix).map((i) => i.control);
    },
    sidebar() {
      let sidebar = this.$store.getters["dashboard/sidebar"] || null;
      return (sidebar && sidebar.collapsed) || false;
    },
    largePanel() {
      return (
        this.$store.getters["dashboard/expandedPanel"] ||
        this.$store.getters["dashboard/fullscreenPanel"] ||
        ""
      );
    },
    isLarge() {
      return this.largePanel && this.panel.name == this.largePanel;
    },
    editablePanel() {
      return this.$store.getters["synoptic/panel"] || null;
    },
    selectedControls() {
      return this.$store.getters["synoptic/selectedControls"] || null;
    }
  },
  watch: {
    signature(n) {
      if (n && this.ready && this.mode == "editor") {
        this.ready = false;
        this.$nextTick(() => {
          this.ready = true;
        });
      }
    },
    editablePanel: {
      handler(n) {
        if (n == this.panel.name) {
          // setTimeout(this.updateSize, 10, this);
        }
      },
      immediate: true
    },
    canvasSize: {
      handler(n, o) {
        if (n && !o && !this.canvasResizer) {
          this.canvasResizer = debounce(() => {
            this.resizing = false;
            this.updateSize();
            this.$nextTick(() => {
              setTimeout(
                () => {
                  this.$root.$emit("panel:resized");
                  this.notififyEditor();
                },
                10,
                this
              );
            });
          }, 500);
        }
        if (n && o && this.canvasResizer) {
          this.canvasResizer();
        }
      },
      immediate: true
    },
    selectedControls(n) {
      if (n && !n.length && this.canvasResizer) {
        this.canvasResizer();
      }
    },
    isLarge() {
      this.resizing = false;
    }
  },
  methods: {
    notififyEditor() {
      if (!this.mode == "editor" || !this.$el) return;
      let r = this?.$el?.getBoundingClientRect() || null;
      if (r) {
        this.trigger({
          action: "panel:size",
          details: {
            h: parseInt((r && r.height) || 0),
            w: parseInt((r && r.width) || 0)
          }
        });
      }
    },
    fit: function() {
      let width = parseFloat(this.canvas.width ?? this.canvas.style?.width);
      if (this._size.width && width) {
        this.zoomFactor = this._size.width / (width || this._size.width);
      }
    },
    updateSize() {
      if (!this.resizing && this.$refs.wrapper) {
        this.fit();
        this.$nextTick(() => {
          let height = parseFloat(
            this.canvas.height ?? this.canvas.style?.height
          );
          let width = parseFloat(this.canvas.width ?? this.canvas.style?.width);
          if (width && height && this.$refs.wrapper) {
            height = height * this.zoomFactor;
            width = width * this.zoomFactor;
            this.$refs.wrapper.style.height = `${height}px`;
            this.$refs.wrapper.style.width = `${width}px`;
            this.resizing = true;
          }
        });
      }
    },
    panelChangeMonitor() {
      let self = this;
      let initEditor = this.mode == "editor";
      this._size = {
        height: 0,
        width: 0,
        ratio: window.devicePixelRatio
      }; // not reactive

      const setSize = (el) => {
        let r = (el && el?.getBoundingClientRect()) || null;
        let h = parseInt((r && r.height) || 0);
        let w = parseInt((r && r.width) || 0);
        if (h != self._size.height || w != self._size.width) {
          self._size.height = h;
          self._size.width = w;
          this.notififyEditor();
        }
        return { h: h, w: w };
      };

      const sizeWatcher = new ResizeObserver((els) => {
        let el = (els && els.length ? els[0] : null)?.target || null;
        let r = setSize(el);
        if (r.w && r.h) {
          if (window.devicePixelRatio != self._size.ratio) {
            // if just browser zoom changed (not the overall browser size)
            // it keeps the synoptic dimension due its aspect ration
            self.resizing = false;
            self._size.ratio = window.devicePixelRatio;
            self.updateSize();
            return;
          }
          self.updateSize();
          if (self.resizing) {
            self.resizing = false;
            if (initEditor) {
              initEditor == false;
              setTimeout(self.updateSize, 10, self);
            } else {
              setTimeout(
                () => {
                  self.$root.$emit("panel:resized");
                },
                10,
                self
              );
            }
          }
        }
      });
      let _p = this.$refs.wrapper;
      while (!_p.classList.contains("inner-panel")) {
        _p = _p.parentElement;
        if (!_p) break;
      }
      if (_p) {
        // setSize(_p); // size will be first time updated by the canvasSize watcher
        sizeWatcher.observe(_p);
        this.ready = true;
        // force initial update while iframed version of this panel
        if (window.location != window.parent.location) {
          this.$nextTick(() => {
            self.updateSize();
          });
        }
        if (!this._formManager) {
          this.$nextTick(() => {
            // since controls must be visible...
            this.setupForms();
          })
        }
      }
    },
    onDelete() {
      this.selectedControls.forEach(({ id }) => this.delControl(id));
    },
    onCopy() {
      return new Promise((resolve) => {
        if (!this.selectedControls.length) return;
        let controls = JSON.parse(JSON.stringify(this.selectedControls));
        // add prop to verify if it's a control on paste action
        controls.forEach((control) => (control.isControl = true));
        let serializedControls = JSON.stringify(controls);
        // try to write into clipboard. If it fails, ask for permission and try again
        writeToClipboard(serializedControls)
          .then(resolve)
          .catch(() => navigator.permissions.query({ name: "clipboard-write" }))
          .then((result) => {
            if (result.state == "granted" || result.state == "prompt") {
              return writeToClipboard(serializedControls).then(resolve);
            }
          })
          .catch(() => {
            // TODO: create fallback function
          });
      });
    },
    onCut() {
      this.onCopy().then(this.onDelete.bind(this));
    },
    onPaste() {
      readFromClipboard()
        .catch(() =>
          navigator.permissions
            .query({ name: "clipboard-read" })
            .then((result) => {
              if (result.state == "granted" || result.state == "prompt") {
                return readFromClipboard();
              }
            })
        )
        .then((text) => {
          if (typeof text == "string") {
            try {
              let controls = JSON.parse(text);
              if (!(controls instanceof Array)) return;
              controls.forEach((control) => {
                if (control.isControl) {
                  delete control.isControl;
                  control.synopticComponent.clientRect.top += 10;
                  control.synopticComponent.clientRect.left += 10;
                  this.addControl(control).then(({ id }) => {
                    this.$store.dispatch("synoptic/addToSelection", id);
                  });
                }
              });
            } catch (e) { }
          }
        });
    },
    onMove(option) {
      if ((this.selectedControls || []).length) {
        this.selectedControls.forEach((control) => {
          control = JSON.parse(JSON.stringify(control));
          let clientRect = control?.synopticComponent?.clientRect || null;
          if (clientRect) {
            let direction = option?.direction || "";
            let size = option?.ctrlKey || false;
            let increment = option?.shiftKey ? 1 : 10;
            switch (direction) {
              case "up":
                if (size) {
                  clientRect.height -= increment;
                } else {
                  clientRect.top -= increment;
                }
                break;
              case "down":
                if (size) {
                  clientRect.height += increment;
                } else {
                  clientRect.top += increment;
                }
                break;
              case "left":
                if (size) {
                  clientRect.width -= increment;
                } else {
                  clientRect.left -= increment;
                }
                break;
              case "right":
                if (size) {
                  clientRect.width += increment;
                } else {
                  clientRect.left += increment;
                }
                break;
            }
            this.$store.dispatch("synoptic/updateControl", {
              id: control.id,
              control: control,
              noMerge: true
            });
          }
        });
      }
    },
    onClearSelection() {
      if (this.mode != "editor" || this.$store.getters["synoptic/isRotating"])
        return;
      this.clearSelectedControls();
      this.$root.$emit("controlSidebar:setContent", SynopticPropertyEditor);
    },
    clearSelectedControls() {
      this.$store.dispatch("synoptic/clearSelectedControls");
    },
    onSelectAll() {
      this.$store.dispatch("synoptic/selectAll");
    },
    delControl(id) {
      this.$store.dispatch("synoptic/delControl", id);
    },
    addControl(control) {
      return this.$store.dispatch("synoptic/addControl", control);
    },
    handleDrop(e) {
      if (e.dataTransfer.getData("text/plain")) {
        try {
          let control = JSON.parse(
            e.dataTransfer.getData("text/plain") || "null"
          );
          if (control) {
            // TODO: for better managing the acceptable content set, replace this method to the JSONDragger.onDrop
            if (control.type && control.type != "control") {
              return true; // do not stop propagation
            }
            e.target.style.opacity = "1";
            //=========================
            var zoom = parseFloat(this.zoomFactor) || 1;
            let rect = this.$refs.synoptic.getBoundingClientRect();
            var x = e.pageX - rect.x - window.scrollX;
            var y = e.pageY - rect.y - window.scrollY;
            var w = control.synopticComponent.clientRect.width;
            var h = control.synopticComponent.clientRect.height;
            w = (w / 2) * zoom;
            h = (h / 2) * zoom;
            if (zoom < 1) {
              h = -1 * h;
            }
            x = x < 0 ? 0 : x > rect.width ? rect.width - w : x;
            y = y < 0 ? 0 : y > rect.height ? rect.height - h : y;
            control.synopticComponent.clientRect.left = x / zoom - w;
            control.synopticComponent.clientRect.top = y / zoom - h;
            this.addControl(control);
          }
        } catch (e) { }
      }
      e.stopPropagation();
      return false;
    },
    openControlMenu(e) {
      if (!this.isEditing || !this.$refs.menu) return;
      e.preventDefault();
      e.stopPropagation();
      let control;
      if (eventMatchesSelector(e, ".synoptic-control")) {
        control = e.target.closest(".resizable-component").__vue__.$parent
          .control;
      }

      // when contextmenu event is triggered by keyboard
      if (e.button == -1) {
        // uses coords of top-left corner of canvas
        let rect = this.$el.getBoundingClientRect();
        e = { clientX: rect.x, clientY: rect.y };
      } else if (!eventMatchesSelector(e, ".synoptic")) return;
      this.$refs.menu.open(e, control);
    },
    trigger(ev) {
      this.$root.$emit("synoptic:event", ev);
    },
    geInputControls() {
      return (this.currentControls || [])
        .map((item) => {
          if (isInputControl(item)) {
            let vitem = this.$children.find(
              (i) => i.control && i.control == item
            );
            if (vitem) {
              return vitem?.$children[0]?.$children[0];
            }
          }
          return null;
        })
        .filter((i) => i != null);
    },
    setupForms() {
      this._formManager = null;
      if (this.mode == "editor") return;
      this._skipForm = null;
      let config = {};
      let formName = "", actionType = "";
      let lst = this.currentControls.filter((item) => {
        if (item.synopticComponent?.on?.click?.actions?.length > 0) {
          actionType = item.synopticComponent.on.click.actions[0]?.type || ""
          if (actionType == "form:submit") {
            formName = item.synopticComponent.on.click.actions[0]?.options?.formName?.value || "";
            if (formName) config[formName] = true;
          }
          else if (actionType.startsWith("tag:")) {
            return true;
          }
        }
        return isInputControl(item);
      });
      lst.forEach((item) => {
        formName = item?.synopticComponent?.formName || "";
        if (formName && !config[formName]) {
          // this._skipForm = this._skipForm || {}
          // this._skipForm[item.id] = true;
          item.synopticComponent.formName = "";
        }
      });
      if (lst && lst.length) {
        this._formManager = new CommandFormManager(this);
      }
    }
  },
  created: function() {
    var self = this;
    self._size = { width: 0, height: 0 };
    self._refreshTimer = null;
    this._timer_sb = null;
  },
  mounted() {
    this.$root.$on("arrows", this.onMove);
    this.$root.$on("delete", this.onDelete);
    this.$root.$on("copy", this.onCopy);
    this.$root.$on("cut", this.onCut);
    this.$root.$on("paste", this.onPaste);
    this.$root.$on("clear-selection", this.onClearSelection);
    this.$root.$on("select-all", this.onSelectAll);
    this.$root.$on("contextmenu", this.openControlMenu);
    this.panelChangeMonitor();
  },
  beforeDestroy() {
    this._formManager =
      (this._formManager && this._formManager.destroy()) || null;
  },
  destroyed() {
    this.$root.$off("arrows", this.onMove);
    this.$root.$off("delete", this.onDelete);
    this.$root.$off("copy", this.onCopy);
    this.$root.$off("cut", this.onCut);
    this.$root.$off("paste", this.onPaste);
    this.$root.$off("clear-selection", this.onClearSelection);
    this.$root.$off("select-all", this.onSelectAll);
    this.$root.$off("contextmenu", this.openControlMenu);
    var self = this;
    if (self._refreshTimer) {
      clearInterval(self._refreshTimer);
      self._refreshTimer = null;
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.synoptic-wrapper {
  /* width: 100%; */
  padding: 0;
  margin: 0;
  /* text-align: center; */
  max-width: 100%;
  overflow: hidden;
  -webkit-text-size-adjust: auto;
}

.synoptic {
  display: inline-block;
  margin: 0 auto;
  /* overflow: hidden; */
  position: relative;
  margin: 0;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
